import { configureStore } from '@reduxjs/toolkit'
import mainSlice from './reducers/mainSlice'
import authSlice from './reducers/authSlice'


const reducer = {
  main:mainSlice,
  auth:authSlice
}

const store = configureStore({
  reducer
})

export default store