import React, {useState} from 'react'


function Loader () {
  return (
    <div className='loader-wrap'>
          <div className="loading">
					  <div className="arc"></div>
					  <div className="arc"></div>
					  <div className="arc"></div>
				  </div>
        </div>
  )

}
export default Loader;