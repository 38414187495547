import React,{ lazy, useEffect } from 'react'
import './App.css';
import './responsive.css';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/font-awesome.css'
import 'nprogress/nprogress.css';
import Loader from './Component/loader'
/* auth files */
const Login = lazy(() => import('./pages/auth/Login'))
const Signup = lazy(() => import('./pages/auth/Signup'))
const Otp = lazy(() => import('./pages/auth/Otp'))
/* auth files end*/
const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const Products = lazy(() => import('./pages/Products'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const HelpSupport = lazy(() => import('./pages/HelpSupport'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Portfolio = lazy(() => import('./pages/Portfolio'))
const GoldStore = lazy(() => import('./pages/GoldStore'))
const MyCart = lazy(() => import('./pages/MyCart'))
const ShippingAddress = lazy(() => import('./pages/ShippingAddress'))
const TransactionHistory = lazy(() => import('./pages/TransactionHistory'));
const Membership = lazy(() => import('./pages/Membership'));
const MyProfile = lazy(() => import('./pages/MyProfile'));

function App() {
  return (
    <>
    <Router>
      <React.Suspense fallback={<Loader/>}>
        <Routes>
          {/* auth routes start */}
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/signup" element={<Signup/>} />
            <Route exact path="/verified/otp" element={<Otp/>} />
            <Route exact path="/ForgotPassword" element={<ForgotPassword/>} />
          {/* auth routes end */}
          <Route exact path="/" element={ <Home/> } />
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/products" element={<Products/>} />
          <Route exact path="/contact" element={<HelpSupport/>} />

          <Route exact path="/dashboard" element={<Dashboard/>}/>
          <Route exact path="/dashboard/portfolio" element={<Portfolio/>} />
          <Route exact path="/dashboard/store/gold" element={<GoldStore/>} />
          <Route exact path="/dashboard/cart" element={<MyCart/>} />
          <Route exact path="/dashboard/address/shipping" element={<ShippingAddress/>} />
          <Route exact path="/dashboard/transaction/history" element={<TransactionHistory/>} />
          <Route exact path="/dashboard/membership" element={<Membership/>} />
          <Route exact path="/dashboard/profile" element={<MyProfile/>} />
          

          {/* <Route exact path="/HelpSupport" element={<HelpSupport/>}></Route> */}
        </Routes>
      </React.Suspense>
    </Router>
      
    </>
  );
}

export default App;