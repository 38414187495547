import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "../Helper/helper";
import { logout } from './authSlice'
const mainSlice = createSlice({
    name:'mainSlice',
    initialState:{
        success:null,
        failed:null,
        testimonialList:{
            isLoading:true,
            data:[]
        },
        cmsList:[]
    },
    reducers:{
        apiFailed:(state,action)=>{
            state.failed = action.payload 
        },
        unsetApiFailed:(state,action)=>{
            state.failed=null;
        },
        apiSucceed:(state,action)=>{
            state.success = action.payload 
        },
        unsetApiSucceed:(state,action)=>{
            state.success=null;
        },
        setTestimonial:(state,action)=>{
            state.testimonialList={
                isLoading:false,
                data:action.payload.data
            }
        },
        setCmsList:(state,action)=>{
            state.cmsList = action.payload.data
        }
    }
});
const {apiFailed,unsetApiFailed,apiSucceed,unsetApiSucceed , setTestimonial, setCmsList} =mainSlice.actions
export default mainSlice.reducer
export const apiSuccess = (value) => async dispatch => {
    dispatch(apiSucceed(value))
}
export const apiFail = (value) => async dispatch => {
    if (value.statusCode === 419) {
        dispatch(logout())   
    }else{
        dispatch(apiFailed(value))
    }
}
export const unSetApiFail = () => async dispatch =>{
    dispatch(unsetApiFailed())
}
export const unSetApiSucc = () => async dispatch =>{
    dispatch(unsetApiSucceed())
}
export const getTestimonial = () => async dispatch => {
    try {
        let res = await callApi('post','/home/testimonial',{})
        dispatch(setTestimonial(res.data))
    } catch (error) {
        dispatch(apiFailed(error))
    }
}
export const getCmsList = () => async dispatch => {
    try {
        let res = await callApi('post','/home/cmslist',{})
        dispatch(setCmsList(res.data))
    } catch (error) {
        dispatch(apiFailed(error))
    }
}