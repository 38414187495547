import axios from "axios";
export const callApi=async (type,url,value=null,headers={})=>{
    try {
       let api_headers = Object.assign({},headers,{'x-access-token':localStorage.getItem('auth-token-user-giraffe')},{'Accept':'application/json'});
        switch (type) {
            case 'post':
                return await axios.post(process.env.REACT_APP_API_BASE_URL+url,value,{headers:api_headers});
            default:
                break;
        }
     } catch (e) {
        if (e.response) {
           throw  e.response.data
        } else {
            throw e.message
        }
     }
    
    
}